<template>
<div class="personal-data">
	<div class="header acea-row row-between-wrapper" ref="header">
      <div class="black" @click="returnChange()">
        <img src="@assets/images/icon_black.png" alt="" />
      </div>
      <p>打样信息</p>
    </div>
  	<div class="set-container">
		<div class="set-list acea-row row-left">
			<p>编号：</p>
			<p class="right">{{proofingData.order_id}}</p>
		</div>
	</div>

	<div class="set-container">
		<div class="set-list acea-row row-left">
			<p>电芯材料：</p>
			<p class="right">
				{{proofingData.package_type}}
			</p>
		</div>
		<div class="set-list acea-row row-left">
			<p>电池封装：</p>
			<p class="right">
				{{proofingData.material_type}}
			</p>
		</div>
		<div class="set-list acea-row row-left">
			<p>放电倍率：</p>
			<p class="right">
				{{proofingData.discharge_rate}}
			</p>
		</div>
		<div class="set-list acea-row row-left">
			<p>电芯形状：</p>
			<p class="right">
				{{proofingData.shape_type}}
			</p>
		</div>
	</div>

	<div class="set-container">
		<div class="set-list acea-row row-left">
			<p>厚度：</p>
			<p class="right" v-if="proofingData.proofing_data">
				{{proofingData.proofing_data.long}}
			</p>
		</div>
		<div class="set-list acea-row row-left">
			<p>宽度：</p>
			<p class="right" v-if="proofingData.proofing_data">
				{{proofingData.proofing_data.width}}
			</p>
		</div>
		<div class="set-list acea-row row-left">
			<p>高度：</p>
			<p class="right" v-if="proofingData.proofing_data">
				{{proofingData.proofing_data.height}}
			</p>
		</div>
	</div>

	<div class="set-container">
		<div class="set-list acea-row row-left">
			<p>期望打样周期：</p>
			<p class="right" v-if="proofingData.proofing_data">
				{{proofingData.proofing_data.delivery_period}}
			</p>
		</div>
	</div>

	<div class="detail-btn acea-row row-left">
      <div class="right pay bule" @click="submit">
        <p>返回</p>
      </div>
    </div>
</div>
</template>

<script type="text/javascript">
export default {
	name:'proofingParam',
	data(){
		return {
			proofingData:'',
		}
	},
    watch: {
        $route(n) {
            if (n.name === 'proofingParam') {
                this.getCartInfo();
            }
        },
    },
    mounted(){
        let that = this;
        that.getCartInfo();
    },
	methods:{
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	    getCartInfo() {
            var that = this;
            that.proofingData = JSON.parse(that.$route.query.data);
        },
	    submit(){
            var that = this;
	        that.$router.go(-1);
	    }
	}
}


</script>

<style type="text/css">
.personal-data { padding-top:50px; }

.personal-data .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.personal-data .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.personal-data .header .black img {
  width: 100%;
  height: 100%;
}
.personal-data .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>



